import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../lib/config";

const AdminHome = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user !== null) {
      const myuser = JSON.parse(user);
      if (myuser.name !== "admin") navigate("/");
    } else {
      navigate("/");
    }
  }, [navigate]);
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(API_URL + "/set")
        .then((res) => {
          setData(res.data.counter);
        })
        .catch((error) => {
          alert("Hata Oluştu");
        });
    };

    fetchData();
  }, []);

  return (
    <Layout>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 ">
          <div className="tw-text-white tw-p-4 tw-border-2">
            Toplam Kurye Başvuruları :{" "}
            <span className="tw-text-xl">{data?.kurye}</span>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 ">
          <div className="tw-text-white  tw-p-4 tw-border-2">
            Toplam Restoran Başvuruları :
            <span className="tw-text-xl">{data?.rest}</span>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 ">
          <div className="tw-text-white  tw-p-4 tw-border-2">
            Toplam İletişim Mailleri :{" "}
            <span className="tw-text-xl">{data?.iletisim}</span>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 ">
          <div className="tw-text-white  tw-p-4 tw-border-2">
            Toplam Waffle Siparişleri :{" "}
            <span className="tw-text-xl">{data?.waffle}</span>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminHome;
